import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from 'antd';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import StayPrimaryPortraitOutlinedIcon from '@mui/icons-material/StayPrimaryPortraitOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';

import styles from '../../organization.module.css';
import { ResponseDataType, getRequest, postRequest } from 'api';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { testImage } from 'utils';

interface MemberInfoType {
  cusOrgId: string;
  email: string;
  name: string;
  avatar?: string;
  phone: string;
  department?: string;
  role?: number;
  groupList: string[];
}

interface OptionInListType {
  value: string;
  label: string;
}

interface MemberInfoResponseDto {
  memberInfo: MemberInfoType;
  departmentList: OptionInListType[];
  groupList: OptionInListType[];
}

interface FormValueType {
  name: string;
  phone: string;
  avatar?: string;
  department?: string;
  role?: number;
  group?: string[];
}

const fontWeightStyle = { fontWeight: 700 };

export default function GeneralInfo() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { memberId, orgId } = useParams();
  const [memberInfo, setMemberInfo] = useState<MemberInfoType>();
  const [departmentOptions, setDepartmentOptions] = useState<
    OptionInListType[]
  >([]);
  const [groupOptions, setGroupOptions] = useState<OptionInListType[]>([]);
  // const [isOpenAddManagerPopup, setIsOpenAddManagerPopup] =
  //   useState<boolean>(false);

  useEffect(() => {
    const GetMemberInfo = async () => {
      const response: ResponseDataType<MemberInfoResponseDto> =
        await getRequest(`/member/member-info/${memberId}`);

      if (response.code) {
        toast.error(response.message);
        navigate(`/organization-list/organization-info/${orgId}`);
      } else {
        setMemberInfo(response.data?.memberInfo);
        form.setFieldsValue({
          name: response.data?.memberInfo.name,
          phone: response.data?.memberInfo.phone,
          avatar: response.data?.memberInfo.avatar,
          department: response.data?.memberInfo.department,
          role: response.data?.memberInfo.role,
          group: response.data?.memberInfo.groupList,
        });
        setDepartmentOptions(
          response.data?.departmentList as OptionInListType[]
        );
        setGroupOptions(response.data?.groupList as OptionInListType[]);
      }
    };

    GetMemberInfo();
  }, [form, memberId, orgId, navigate]);

  const UpdateMemberInfo = async (value: FormValueType) => {
    const response: ResponseDataType<string> = await postRequest(
      '/member/update-info',
      {
        memberId: memberId,
        name: value.name,
        phone: value.phone,
        avatar: value.avatar,
        departmentId: value.department,
        role: value.role,
        groupList: value.group,
      }
    );

    if (response.code) {
      if (typeof response.message === 'object') {
        response.message.forEach((message: string) => toast.error(message));
      } else toast.error(response.message);
    } else {
      toast.success('Cập nhật thông tin tài khoản thành công');
    }
  };

  const HandleSubmitForm = (value: FormValueType) => {
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận cập nhật thông tin tài khoản?',
      onOk() {
        UpdateMemberInfo(value);
      },
    });
  };

  return (
    <Grid container spacing={5} marginTop={-2}>
      <Grid item>
        <Card
          title='Thông tin chi tiết'
          className={styles.list_card}
          style={{ width: 500 }}
        >
          <Form form={form} onFinish={HandleSubmitForm}>
            <Row>
              <Col span={8}>
                <div
                  className={styles.card_content_label}
                  style={{ marginTop: 0 }}
                >
                  <EmailOutlinedIcon />
                  &nbsp;&nbsp;Email:
                </div>
              </Col>
              <Col
                span={16}
                style={{ width: '70%', color: '#009BE5', fontWeight: '600' }}
              >
                {memberInfo?.email}
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={8}>
                <div className={styles.card_content_label}>
                  <PermIdentityOutlinedIcon />
                  &nbsp;&nbsp;Tên:
                </div>
              </Col>
              <Col span={16}>
                <Form.Item
                  name='name'
                  rules={[{ required: true, message: 'Cần nhập tên!' }]}
                >
                  <Input style={fontWeightStyle} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className={styles.card_content_label}>
                  <StayPrimaryPortraitOutlinedIcon />
                  &nbsp;&nbsp;Phone:
                </div>
              </Col>
              <Col span={16}>
                <Form.Item name='phone'>
                  <Input style={fontWeightStyle} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className={styles.card_content_label}>
                  <ImageOutlinedIcon />
                  &nbsp;&nbsp;Avatar:
                </div>
              </Col>
              <Col span={16}>
                <Form.Item
                  name='avatar'
                  rules={[
                    () => ({
                      async validator(_, value) {
                        if (!value) return Promise.resolve();
                        var checkFlag;
                        try {
                          checkFlag = await testImage(value, 2000);
                        } catch (error) {
                          checkFlag = false;
                        }
                        if (checkFlag) {
                          return Promise.resolve();
                        } else return Promise.reject('Link avatar lỗi!');
                      },
                    }),
                  ]}
                >
                  <Input style={fontWeightStyle} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className={styles.card_content_label}>
                  <DomainOutlinedIcon />
                  &nbsp;&nbsp;Phòng ban:
                </div>
              </Col>
              <Col span={16}>
                <Form.Item name='department'>
                  <Select
                    optionFilterProp='label'
                    showSearch={true}
                    style={{ marginBottom: 0 }}
                    allowClear
                    labelRender={(label) => (
                      <div style={fontWeightStyle}>{label.label}</div>
                    )}
                    options={departmentOptions}
                    optionRender={(option) => (
                      <div style={fontWeightStyle}>{option.label}</div>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className={styles.card_content_label}>
                  <PermIdentityOutlinedIcon />
                  &nbsp;&nbsp;Vai trò:
                </div>
              </Col>
              <Col span={16}>
                <Form.Item
                  name='role'
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && !getFieldValue('department')) {
                          return Promise.reject('Chưa chọn phòng ban');
                        }

                        if (getFieldValue('department') && !value) {
                          return Promise.reject(
                            'Cần chọn vai trò trong phòng ban'
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select
                    style={{ marginBottom: 0 }}
                    allowClear
                    options={[
                      { label: 'Trường phòng', value: 1 },
                      { label: 'Nhân viên', value: 2 },
                    ]}
                    labelRender={(label) => (
                      <div style={fontWeightStyle}>{label.label}</div>
                    )}
                    optionRender={(option) => (
                      <div style={fontWeightStyle}>{option.label}</div>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className={styles.card_content_label}>
                  <DomainOutlinedIcon />
                  &nbsp;&nbsp;Group:
                </div>
              </Col>
              <Col span={16}>
                <Form.Item name='group'>
                  <Checkbox.Group
                    style={fontWeightStyle}
                    options={groupOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button type='primary' htmlType='submit'>
              Cập nhật
            </Button>
          </Form>
        </Card>
      </Grid>
      {/* <Grid item>
        <Card
          title='Quản lý trực tiếp'
          className={styles.list_card}
          extra={
            <div
              style={{ borderRadius: '50%', cursor: 'pointer', lineHeight: 0 }}
              onClick={() => setIsOpenAddManagerPopup(true)}
            >
              <AddCircleOutlineOutlinedIcon style={{ fontSize: 30 }} />
            </div>
          }
        >
        </Card>
      </Grid>
      <Grid item>
        <Card
          title='Nhân viên trực tiếp'
          className={styles.list_card}
          extra={
            <div
              style={{ borderRadius: '50%', cursor: 'pointer', lineHeight: 0 }}
              onClick={() => setIsOpenAddManagerPopup(true)}
            >
              <AddCircleOutlineOutlinedIcon style={{ fontSize: 30 }} />
            </div>
          }
        ></Card>
      </Grid> */}
    </Grid>
  );
}
